import { Injectable } from "@angular/core";

export enum Environment {
  Prod = "prod",
  Staging = "staging",
  Test = "test",
  Dev = "dev",
  Local = "local",
}

@Injectable({ providedIn: "root" })
export class EnvService {
  private _env: Environment;
  private _apiUrl: string;
  private _authMapKey: string;

  get env(): Environment {
    return this._env;
  }

  get apiUrl(): string {
    return this._apiUrl;
  }

  get mapAPIKey(): string {
    return this._authMapKey;
  }

  constructor() { }

  init(): Promise<void> {
    return new Promise((resolve) => {
      this.setEnvVariables();
      resolve();
    });
  }

  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname;

    this._authMapKey = "AIzaSyAVEh270eSAqyYSTywIsrWWYj1D48PL6W0";
    if (/^.*localhost.*/.test(hostname)) {
      this._env = Environment.Local;
      this._apiUrl = "http://localhost:81/uat/api-csrgs/";
    } else if (/^csrgs.org/.test(hostname)) {
      this._env = Environment.Prod;
      this._apiUrl = "http://www.csrgs.org/api-csrgs/";
    } else {
      console.warn(`Cannot find environment for host name ${hostname}`);
    }
  }
}
